@import '../../../src/assets/styles/variable';

mat-cell {
	mat-checkbox {
		color: $filed-blue;
	}
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-pseudo-checkbox-checked {
	background-color: $filed-blue !important;
}

.mat-form-field.mat-focused {
	.mat-form-field-ripple {
		background-color: $filed-blue;
		margin: 0;
		height: 0;
	}
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent
	.mat-checkbox-ripple
	.mat-ripple-element {
	background-color: $filed-blue;
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: $filed-blue;
}

.mat-form-field-label,
.mat-focused .mat-form-field-label,
.mat-select-value,
.mat-select-arrow,
.mat-form-field.mat-focused .mat-select-arrow {
	color: $filed-blue;
}

.mat-ink-bar {
	background-color: white !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $filed-blue;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: $filed-blue;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
	background: $filed-blue !important;
}

.genericButtonColorAndBackground {
	background: $filed-blue;
	box-shadow: 0 2px 2px 0 rgba(11, 11, 11, 0.14),
		0 3px 1px -2px rgba(11, 11, 11, 0.12), 0 1px 5px 0 rgba(11, 11, 11, 0.2);
	color: white;
}

.genericButtonColorAndBackground:hover {
	background: rgba($filed-blue, 0.9);
	box-shadow: 0 2px 2px 0 rgba(11, 11, 11, 0.14),
		0 3px 1px -2px rgba(11, 11, 11, 0.12), 0 1px 5px 0 rgba(11, 11, 11, 0.2);
	color: white;
}

.genericButtonColor {
	color: $filed-blue;
	font-weight: 500;
}

.genericCancelButtonColor {
	color: $darkest-gray;
}

.genericButtonColor:hover {
	color: $filed-blue;
	font-weight: 500;
	background: rgba($filed-blue, 0.1) !important;
}

.cancelButtonColor {
	color: $filed-dark-gray;
}

.cancelButtonColor:hover {
	color: $filed-dark-gray;
	background: rgba(11, 11, 11, 0.1);
}

.mat-flat-button {
	color: $filed-blue;
	text-align: center;
	margin: 0;
}

.mat-flat-button--overlay {
	-webkit-appearance: none;
	background: none !important;
	transition: none !important;
	box-shadow: none !important;
}

.mat-flat-button:hover,
.mat-flat-button:focus {
	-webkit-appearance: none;
	text-decoration: underline !important;
	background: none !important;
}

.mat-tab-label {
	opacity: 1 !important;
	font-weight: 500;
	color: $filed-dark-gray;
}

.mat-tab-label:focus:not(.mat-tab-disabled) {
	color: $filed-blue;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	background: $filed-lighter-gray;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	//color: $filed-dark-gray;
	font-weight: 500;
	display: block;
}

input.mat-input-element {
}

.mat-drawer-backdrop.mat-drawer-shown {
	visibility: hidden !important;
}

.mat-drawer.mat-drawer-push {
	background: $filed-dark-gray;
}

.mat-drawer:not(.mat-drawer-side) {
	box-shadow: none;
}

::ng-deep.mat-drawer-container {
	background: $white;
}

.mat-drawer-container {
	background-color: transparent !important;
}

.mat-tab-group {
	background: $white;
}

//That container from every page which contains buttons

.page_container_buttons {
	max-width: 1024px;
	width: 100%;
	margin: auto;
	text-align: right;
}

.mat-raised-button:not([class*='mat-elevation-z']) {
	box-shadow: none;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
	font-size: 14px;
	font-weight: 500;
}

// Generic buttons mat-raised-button

.mat-raised-button {
	background: $filed-blue;
	border-radius: 50px !important;
	color: $white;
	padding: 0 30px !important;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	transition: background 0.3s ease;
}

.mat-raised-button:hover {
	background: $filed-blue;
	border-radius: 50px;
	color: $white;
}

// Generic buttons mat-button

.mat-button {
	color: $darkest-gray;
	text-transform: uppercase;
}

.mat-button,
.mat-raised-button {
	&.mat-button-spinner {
		.mat-button-wrapper {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			color: $white;
			font-size: 14px;

			.spinner {
				position: static;
				margin-left: 10px;
			}
		}
	}
}

.mat-menu-panel .mat-menu-content:not(:empty) {
	padding: 0;
}

//Over-ride toggle color

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: rgba(47, 217, 248, 0.2);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
	background-color: $white;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
	background-color: $white;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: $filed-blue;
}

.mat-slide-toggle-bar {
	background: $filed-light-gray;
}

// Primary Color #38CCF6

.primary-color {
	color: $filed-blue;
}

.filed-coral-color {
	color: $filed-coral;
}

// Paragraph Color #38CCF6

.paragraph-color {
	color: $darkest-gray;
}

// Heading Color #38CCF6

.heading-color {
	color: $darkest-gray;
}

// Secondary Color #616161

.secondary-color {
	color: $filed-dark-gray;
}

// Third Color #616161

.third-color {
	color: $darkest-gray;
}

// Fourth Color #616161

.fourth-color {
	color: $filed-dark-gray;
}

.fourth-color-background {
	background: $filed-dark-gray;
}

.fiv-color-background {
	background: $filed-lightest-gray;
}

// Styling table header

.header_table_title {
	font-size: 14px;
	color: $darkest-gray;
	font-weight: 500;
	overflow: hidden;
	text-overflow: clip;
}

// Styling table cell

.table_cell {
	font-size: 14px;
	color: $filed-dark-gray;
	font-weight: 300;
	justify-content: left !important;
	padding-left: 8px;
	padding-right: 8px;
}

// Material step line
.mat-stepper-horizontal-line {
	max-width: 64px;
}

// Mat header Container Box-shadow
::ng-deep.mat-horizontal-stepper-header-container {
	box-shadow: 0 2px 2px 0 rgba(11, 11, 11, 0.14),
		0 3px 1px -2px rgba(11, 11, 11, 0.12), 0 1px 5px 0 rgba(11, 11, 11, 0.2) !important;
}

// Position card content
.mat-expansion-panel-content {
	position: relative;
}

// SideBar Summary

html body {
}

//Third color children

.third-color-children {
	color: $filed-dark-gray;
}

.mat-tab-group {
	background: $filed-lightest-gray;
}

::ng-deep .mat-tab-body {
	background: $white;
}

.mat-tab-labels {
	background: $white;
}

::ng-deep .mat-tab-label-container {
	background: $white;
}

.border-radius-6 {
	border-radius: 6px;
}

.side-out-menu-bg {
	background: $filed-lightest-gray;
}

.mat-radio-disabled,
.mat-radio-disabled .mat-radio-label {
	cursor: not-allowed !important;
}

.mat-option {
	&-disabled {
		cursor: not-allowed !important;
		background-color: rgba($filed-blue, 0.2);
		color: $filed-blue;

		.mat-option-text {
			color: $filed-dark-gray;
		}
	}
}

// Align numeric cells to the right

.align-right-table-numeric-cells {
	div {
		width: 100% !important;
		text-align: right !important;
	}
}

.mat-select-panel {
	margin-top: 37px;

	.mat-option {
		&:hover {
			background-color: rgba($filed-blue, 0.2);
			color: $filed-blue;

			span {
				color: $filed-blue;
			}
		}
	}
}

.mat-select-panel {
	.mat-active {
		.mat-option-text {
			&:hover {
				color: $filed-dark-gray;
			}
		}
	}
}

.button-custom-tooltip {
	font-size: 12px;
	margin-top: -10px !important;
}

.title-custom-tooltip {
	padding: 12px 20px !important;
}

.wrapper-custom-tooltip {
	font-size: 13px;
}

.mat-expansion-panel {
	&:not([class*='mat-elevation-z']) {
		&.no-border-effect {
			box-shadow: none;
		}
	}
}

.custom-dialog-container .mat-dialog-container {
	overflow-y: hidden !important;
	overflow-x: hidden !important;
}

.stepper-dialog-container {
	position: fixed !important;
	.mat-dialog-container {
		overflow-y: hidden !important;
		overflow-x: hidden !important;
	}
}

.date-time-dialog mat-dialog-container {
	padding: 0;
	box-shadow: 0px 3px 6px #00000029;
	overflow: visible;
}

.custom-modalbox {
	mat-dialog-container {
		background-color: #fafafa !important;
	}
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
	display: none !important;
}
