:root {
	// * Grey Shades * //
	--primary-grey-shade: #4a4a4a;
	--primary-dark-grey-shade: #121212;
	--grey-shade-1: #b7b7b7;
	--grey-shade-2: #929292;
	--grey-shade-3: #878787;
	--grey-shade-4: #7a7a7a;
	--grey-shade-5: #ebebeb;
	--grey-shade-6: #e4e4e4;
	--grey-shade-7: #a7a7a7;

	// * Red Shades * //
	--primary-red-shade: #c5221f;
	--accent-red-shade: #ffeae8;
	--red-shade-1: #d32f2f;

	// * Green Shades * //
	--primary-green-shade: #006123;
	--primary-dark-green-shade: #2b5b00;
	--accent-green-shade: #e2f9e8;

	// * Blue Shades * //
	--primary-blue-shade: #1a73e9;
	--primary-dark-blue-shade: #005ed8;
	--accent-blue-shade: #cadefa;
	--blue-shade-1: #dce1e8;
	--blue-shade-2: #98a2b3;
	--blue-shade-3: #667085;
	--blue-shade-4: #f0f6ff;
	--blue-shade-5: #fcfcfd;

	// * Yellow Shades * //
	--primary-yellow-shade: #ffdd43;

	// * Theme Colors * //
	--primary-color: var(--primary-blue-shade);
	--secondary-color: var(--primary-dark-blue-shade);
	--alternate-primary-color: var(--primary-grey-shade);

	// * Font Colors * //
	--primary-font-color: #000000;
	--primary-dark-font-color: var(--primary-dark-grey-shade);
	--primary-light-font-color: var(--primary-grey-shade);
	--secondary-font-color: var(--grey-shade-4);
	--label-font-color-dark: var(--alternate-primary-color);
	--label-font-color-normal: var(--grey-shade-3);
	--tertiary-font-color: var(--blue-shade-3);
	--label-font-color: var(--grey-shade-2);
	--placeholder-font-color: var(--grey-shade-2);
	--hyperlink-font-color: var(--secondary-color);
	--error-font-color: var(--error-color);

	// * Button Colors * //
	--primary-btn-color: var(--primary-color);
	--primary-dark-btn-color: var(--secondary-color);
	--secondary-btn-color: var(--alternate-primary-color);
	--secondary-dark-btn-color: var(--primary-dark-grey-shade);
	--disabled-btn-color: var(--grey-shade-1);

	// * Input Colors * //
	--input-border-color: var(--blue-shade-1);
	--input-focused-border-color: var(--blue-shade-1);
	--input-border-error-color: var(--red-shade-1);
	--input-background-color-disabled: var(--grey-shade-1);
	--input-label-color: var(--grey-shade-2);
	--input-font-color: var(--primary-dark-grey-shade);
	--input-error-font-color: var(--red-shade-1);

	// * Icon Colors * //
	--secondary-icon-color: var(--blue-shade-2);
	--selected-icon-color: var(--primary-yellow-shade);
}
