.save-search-dialog {
	position: fixed !important;
	bottom: 0;
	right: 0;
	box-shadow: 0 0;
	margin-right: 100px;
	margin-bottom: 70px;
}

::ng-deep .mat-dialog-container {
	border-radius: 12px !important;
	background: red !important;
	overflow: auto;
}
