$primary-purple: #7a5fba;
$text-primary-purple: #a489e5;
$purple-more: #6d55a6;
$secondary-purple: #bba9e533;

$text-gray: #525252;
$text-black: #000000;

$gray: #dcdcdc;
$gray-a: #f3f3f3;

$primary-white: #ffffff;

:host {
	--background-gray: #f5f5f5;
	--background-gray-500: #d9d9d9;
	--text-gray: #cecece;
	--text-gray-500: #8b8b8b;
	--text-gray-200: #aaaaaa;
	--text-black: #000000;
	--text-white: #ffffff;

	--text-blue-700: #1f70cf;
	--ai-gradient: linear-gradient(
		90deg,
		rgba(3, 104, 208, 0.6) 0%,
		rgba(74, 124, 206, 0.6) 50%,
		rgba(75, 69, 217, 0.6) 100%
	);
}
